import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Games.css";

const Games = ({ title }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const renderCategory = (category) => {
    const filteredProducts = products?.filter(
      (item) => item.category === category
    );
    if (filteredProducts && filteredProducts.length > 0) {
      return (
        <div className="popular-games">
          <div className="titlee">
            <h5>{category}</h5>
            <span onClick={() => navigate("/games")}>View More</span>
          </div>
          <div className="games-list">
            {filteredProducts.map((item, index) => (
              <div
                key={index}
                className="game"
                onClick={() => navigate(`/product/${item?.name}`)}
              >
                <img
                  src={`https://tangyanstore.in/${item?.image}`}
                  alt="pro-img"
                />
                <h5 className="m-0">{item?.name}</h5>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="explore-products-container">
      {renderCategory("Mobile Legends")}
      {renderCategory("Popular")}
      {renderCategory("Other games")}
      {renderCategory("Wallet/Gift Card Top Up")}
      {renderCategory("Premium Accounts")}
      {renderCategory("Social Media")}
      {renderCategory("Other")}
    </div>
  );
};

export default Games;
